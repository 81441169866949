import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { CouponsPage } from './coupons/coupons.page';

const routes: Routes = [
  {
    // PWA (No encrypted appid)
    path: '',
    loadChildren: () => import('./startup/startup.module').then(m => m.StartupPageModule)
    //component: TabsPage,
    // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    // loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  // {
  //   path: 'rewards/:id',
  //   loadChildren: () => import('./startup/startup.module').then(m => m.StartupPageModule)
  //   //component: TabsPage,
  //   // loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  //   // loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  // },
  // {
  //   // sa coupons adverts
  //   path: 'rewards/:id/advert/:advertId',
  //   loadChildren: () => import('./startup/startup.module').then(m => m.StartupPageModule)
  // },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  },
  {
    path: 'coupons/livelife/:categoryId',
    component: CouponsPage
  },
  {
    path: 'ecommerce',
    //component: EcommercePage
    loadChildren: () => import('./ecommerce/ecommerce.module').then( m => m.EcommercePageModule)
  },
  {
    path: 'ecommerce-store/:productId/:productName',
    //component: EcommercePage
    loadChildren: () => import('./ecommerce/ecommerce-store/ecommerce-store.module').then( m => m.EcommerceStorePageModule)
  },
  {
    path: 'proofOfPurchase',
    loadChildren: () => import('./shopping-cart/proof-of-purchase/proof-of-purchase.module').then( m => m.ProofOfPurchasePageModule)
  },
  {
    path: 'wallet',
    loadChildren: () => import('./wallet/wallet.module').then( m => m.WalletPageModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./coupons/coupons.module').then( m => m.CouponsPageModule)
  },
  {
    path: 'coupons-subscription-options',
    loadChildren: () => import('./coupons/coupons-subscription-options/coupons-subscription-options.module')
      .then( m => m.CouponsSubscriptionOptionsPageModule)
  },
  {
    path: 'coupons-subscribe/:paymentMethod',
    loadChildren: () => import('./coupons/coupons-subscribe/coupons-subscribe.module')
      .then( m => m.CouponsSubscribePageModule)
  },
  {
    path: 'coupons-retailers',
    loadChildren: () => import('./coupons/coupons-retailers/coupons-retailers.module').then( m => m.CouponsRetailersPageModule)
  },
  {
    path: 'coupons-categories/:retailerId',
    loadChildren: () => import('./coupons/coupons-categories/coupons-categories.module').then( m => m.CouponsCategoriesPageModule)
  },
  {
    path: 'coupons-products/retailer/:retailerId/category/:categoryId',
    loadChildren: () => import('./coupons/coupons-products/coupons-products.module').then( m => m.CouponsProductsPageModule)
  },
  {
    path: 'coupons-basket/:retailerId',
    loadChildren: () => import('./coupons/coupons-basket/coupons-basket.module').then( m => m.CouponsBasketPageModule)
  },
  {
    path: 'coupons-basket-checkedout/:urlCode',
    loadChildren: () => import('./coupons/coupons-basket/coupons-basket-checkedout/coupons-basket-checkedout.module')
      .then( m => m.CouponsBasketCheckedoutPageModule)
  },
  {
    path: 'coupons-basket-history/:retailerId',
    loadChildren: () => import('./coupons/coupons-basket/coupons-basket-history/coupons-basket-history.module')
      .then( m => m.CouponsBasketHistoryPageModule)
  },
  {
    // for redirecting from basket checkout
    path: 'coupons-basket-history/:retailerId/:basketId',
    loadChildren: () => import('./coupons/coupons-basket/coupons-basket-history/coupons-basket-history.module')
      .then( m => m.CouponsBasketHistoryPageModule)
  },
  {
    path: 'coupons-intro',
    loadChildren: () => import('./coupons/coupons-intro/coupons-intro.module').then( m => m.CouponsIntroPageModule)
  },
  {
    // livelife coupons
    path: 'coupons-livelife-categories',
    loadChildren: () => import('./coupons/coupons-livelife/coupons-livelife-categories/coupons-livelife-categories.module')
      .then( m => m.CouponsLivelifeCategoriesPageModule)
  },
  {
    path: 'coupons-livelife-products/category/:categoryId',
    loadChildren: () => import('./coupons/coupons-livelife/coupons-livelife-products/coupons-livelife-products.module')
      .then( m => m.CouponsLivelifeProductsPageModule)
  },
  {
    path: 'coupons-livelife-basket',
    loadChildren: () => import('./coupons/coupons-livelife/coupons-livelife-basket/coupons-livelife-basket.module')
      .then( m => m.CouponsLivelifeBasketPageModule)
  },
  {
    path: 'proof-of-purchase',
    loadChildren: () => import('./coupons/coupons-livelife/proof-of-purchase/proof-of-purchase.module')
      .then( m => m.ProofOfPurchasePageModule)
  },
  {
    path: 'fuel',
    loadChildren: () => import('./fuel/fuel.module').then( m => m.FuelPageModule)
  },
  {
    path: 'user-registration',
    loadChildren: () => import('./user-registration/user-registration.module').then( m => m.UserRegistrationPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
  },
  // {
  //   path: 'messages',
  //   loadChildren: () => import('./messages/messages.module').then( m => m.MessagesPageModule)
  // },
  {
    path: 'wallet-register',
    loadChildren: () => import('./wallet/wallet-register/wallet-register.module').then( m => m.WalletRegisterPageModule)
  },
  {
    path: 'wallet-consumer',
    loadChildren: () => import('./wallet/consumer/consumer.module').then( m => m.ConsumerPageModule)
  },
  {
    path: 'wallet-merchant',
    loadChildren: () => import('./wallet/merchant/merchant.module').then( m => m.MerchantPageModule)
  },
  {
    path: 'wallet-payroll',
    loadChildren: () => import('./wallet/payroll/payroll.module').then( m => m.PayrollPageModule)
  },
  {
    path: 'travel',
    loadChildren: () => import('./travel/travel.module').then( m => m.TravelPageModule)
  },
  {
    path: 'travelPage',
    loadChildren: () => import('./travel/display/display.module').then( m => m.DisplayPageModule)
  },
  {
    path: 'airtime',
    loadChildren: () => import('./airtime/airtime.module').then( m => m.AirtimePageModule)
  },
  {
    path: 'greydot-register',
    loadChildren: () => import('./airtime/greydot/greydot-register/greydot-register.module').then( m => m.GreydotRegisterPageModule)
  },
  {
    path: 'greydot-rewards',
    loadChildren: () => import('./airtime/greydot/greydot-rewards/greydot-rewards.module').then( m => m.GreydotRewardsPageModule)
  },
  {
    path: 'user-terms/:docName',
    loadChildren: () => import('./user-registration/user-terms/user-terms.module').then( m => m.UserTermsPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'join-us',
    loadChildren: () => import('./join-us/join-us.module').then( m => m.JoinUsPageModule)
  },
  {
    path: 'partner-programs',
    loadChildren: () => import('./partner-programs/partner-programs.module').then( m => m.PartnerProgramsPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./registration/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'register/:referrer',
    loadChildren: () => import('./registration/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'membership-register',
    loadChildren: () => import('./membership/membership-register/membership-register.module').then( m => m.MembershipRegisterPageModule)
  },
  {
    path: 'register-otp',
    loadChildren: () => import('./registration/register-otp/register-otp.module').then( m => m.RegisterOtpPageModule)
  },
  {
    path: 'register-otp/:referrer',
    loadChildren: () => import('./registration/register-otp/register-otp.module').then( m => m.RegisterOtpPageModule)
  },
  {
    path: 'membership-payment',
    loadChildren: () => import('./membership/membership-payment/membership-payment.module').then( m => m.MembershipPaymentPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'membership-payment',
    loadChildren: () => import('./membership/membership-payment/membership-payment.module').then( m => m.MembershipPaymentPageModule)
  },
  {
    path: 'membership-register',
    loadChildren: () => import('./membership/membership-register/membership-register.module').then( m => m.MembershipRegisterPageModule)
  },
  {
    path: 'how-to',
    loadChildren: () => import('./how-to/how-to.module').then( m => m.HowToPageModule)
  },
  {
    path: 'stokvel-registration',
    loadChildren: () => import('./stokvels/registration/stokvel-registration.module').then( m => m.StokvelRegistrationPageModule)
  },
  {
    path: 'your-score',
    loadChildren: () => import('./your-score/your-score.module').then( m => m.YourScorePageModule)
  },
  {
    path: 'scan-and-win',
    loadChildren: () => import('./scan-and-win/scan-and-win.module').then( m => m.ScanAndWinPageModule)
  },
  {
    path: 'distributors',
    loadChildren: () => import('./distributors/distributors.module').then( m => m.DistributorsPageModule)
  },
  {
    path: 'stokvels',
    loadChildren: () => import('./stokvels/stokvels.module').then( m => m.StokvelsPageModule)
  },
  {
    path: 'taxi-validation',
    loadChildren: () => import('./taxi-association/taxi-validation/taxi-validation.module').then( m => m.TaxiValidationPageModule)
  },
  {
    path: 'taxi-detail',
    loadChildren: () => import('./taxi-association/taxi-detail/taxi-detail.module').then( m => m.TaxiDetailPageModule)
  }




  // {
  //   path: 'tabs',
  //   loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
  // },
  // {
  //   path: 'startup',
  //   loadChildren: () => import('./startup/startup.module').then( m => m.StartupPageModule)
  // }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
